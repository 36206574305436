import { CommonLayout } from '~/components/layouts/CommonLayout';
import { TITLEBAR_HEIGHT } from '~/components/layouts/CommonLayout/const';

export const MaintenancePage = () => {
  return (
    <CommonLayout isWidthFixed={false}>
      <div
        className="tw-flex tw-h-full tw-items-center tw-justify-center tw-bg-gray-50 tw-px-4"
        style={{ paddingBottom: `${TITLEBAR_HEIGHT}px` }}
      >
        <div className="tw-text-center tw-text-gray-700">
          <p className="tw-text-ui20 tw-font-semibold">
            夜間メンテナンスのため、
            <wbr />
            <span className="tw-inline-block">サイト停止中です</span>
          </p>
          <p className="tw-mt-4 tw-text-ui16 tw-font-light">
            毎日2:00~5:00まではシステムメンテナンス時間のためご利用できません。
            <br />
            メンテナンス時間終了後に再度ご利用ください。
          </p>
        </div>
      </div>
    </CommonLayout>
  );
};
