import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
  isRouteErrorResponse,
} from '@remix-run/react';
import { captureRemixErrorBoundaryError } from '@sentry/remix';
import './styles/globals.css';
import { ReactNode } from 'react';

import { MaintenancePage } from './components/pages/maintenance/Maintenance';
import { NotFoundPage } from './components/pages/notFound/NotFound';
import { AppProvider } from './providers/app';

const Document = ({ children }: { children: ReactNode }) => {
  return (
    <html lang="ja">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="noindex" />
        <Meta />
        <Links />
      </head>
      <body>{children}</body>
    </html>
  );
};

export const ErrorBoundary = () => {
  console.log('⭐️ErrorBoundary');
  const error = useRouteError();
  console.log('⭐️error', error);
  console.log('isRouteErrorResponse', isRouteErrorResponse(error));

  if (error instanceof Response && error.status === 503) {
    console.log('⭐️⭐️⭐️⭐️⭐️⭐️error instanceof Response', error);
    return (
      <Document>
        <MaintenancePage />
        <Scripts />
      </Document>
    );
  }

  // if (isRouteErrorResponse(error) && error.status === 503) {
  //   return (
  //     <Document>
  //       <MaintenancePage />
  //       <Scripts />
  //     </Document>
  //   );
  // }

  if (isRouteErrorResponse(error) && error.status === 404) {
    return (
      <Document>
        <NotFoundPage />
        <Scripts />
      </Document>
    );
  }

  captureRemixErrorBoundaryError(error);
  return (
    <Document>
      <main
        style={{
          display: 'flex',
          width: '100%',
          height: '100dvh',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#FFFBF6',
        }}
      >
        <h2
          style={{
            color: '#5D5756',
            fontSize: '20px',
          }}
        >
          予期せぬエラーが発生しました
        </h2>
        <p
          style={{
            color: '#5D5756',
            fontSize: '16px',
          }}
        >
          システム管理者にお知らせください。
        </p>
      </main>
      <Scripts />
    </Document>
  );
};

export default function App() {
  return (
    <Document>
      <AppProvider>
        <Outlet />
      </AppProvider>
      <ScrollRestoration />
      <Scripts />
    </Document>
  );
}

export function HydrateFallback() {
  return (
    <Document>
      <p>Loading...</p>
      <Scripts />
    </Document>
  );
}
